<template>
    <div class="overlay">
        <v-container fluid pa-0 class="pt-15">
            <v-row no-gutters style="padding-top: 100px;">
                <v-col cols="12" class="text-center">
                    <img src="../assets//outstandingThankyou.svg" width="93px" height="93px" />
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-10">
                <v-col class="text-center">
                    <p class="black--text font-weight-bold text-xl" style="font-size: 25px;padding-bottom: 0;">Thank You
                        !</p>
                </v-col>
            </v-row>
            <v-row no-gutters class="text-center">
                <v-col>
                    <p>Your previous transaction was successful</p>
                </v-col>
            </v-row>
        </v-container>
        <v-footer absolute style="background-color: transparent;">
            <v-row no-gutters>
                <v-col class="text-center parking-session">
                    <p>Taking you to the new parking session</p>
                </v-col>
            </v-row>
        </v-footer>
    </div>
</template>
<script>
export default {

    mounted() {
        // Wait for 2 seconds (2000 milliseconds)
        setTimeout(() => {
            // Navigate to the desired route, e.g., '/new-route'
            if (this.checkOTPVerified) {
                this.$router.replace('/ondemand');
            }
            else{
                this.$router.replace('/ondemand/create')
            }
        }, 2000);
    },
    computed: {
        checkOTPVerified() {
            let odDetails = localStorage.getItem('odDetails');
            odDetails = odDetails ? JSON.parse(odDetails) : {};
            return odDetails?.otpVerified
        },
    }
};
</script>
<style>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, white, transparent);
    /* Gradient from white to transparent */
    pointer-events: none;
    /* Allows clicking through the overlay */
}

.parking-session {
    /* Taking you to the new parking session */



    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;


    color: #F2555C;


}
</style>
